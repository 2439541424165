import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from "react-query";
import { Button } from '@mui/material';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import apiClient from "../../request/http-common";

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { setInvestments } from '../../request/Analysis/hitorySlice';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';


const Reinvestment = (props) => {
    const navigate = useNavigate();

    const {investment } = props;
    const plans = useSelector((state) => state.plans.plans);
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);
    const coins = useSelector((state) => state.coins.coins);
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const addresses = useSelector((state) => state.coins.addresses);

    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);

    const [error, setErros] = useState('');
    const [amount, setAmount] =useState(0);
    
    const [barstate, setBarState] = useState({
        top: false,
        bottom: false,
        bottom: false,
        right: false,
      });


    const togglebottomBar = () => {
        const event = new Event("toggleReinvestmentDrawer");
        window.dispatchEvent(event);
      }

      const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return;
        }
        setBarState({ ...barstate, [anchor]: open });
      };

      
    const [planId, setPlanId] = useState(investment.investment_plan_id);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [source, setSource] = useState('');
    const [currentDuration, setCurrentDuration] = useState(investment.duration);

    const changePlan = (planId) => {
        plans.forEach(plan => {
            if (parseInt(plan.id) === parseInt(planId)) {
                setSelectedPlan(plan);
            }
            
        });
    }

      const { isLoading: isInvesting, mutate: postInvestment } = useMutation(
        async () => {
          return await apiClient.post(`/api/invest`, {
            investment_plan_id: planId,
            amount: amount, 
            rate: 1,
            reinvested: investment.id,
            duration: currentDuration,
            source: source
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                dispatch(setInvestments(res.data.investments));
                setTimeout(() => {
                  navigate('/investments');
                }, 2000);
              }            
          },
          onError: (err) => {

                if (err.response?.data.length) {
                  let myerror = err.response?.data || err;         
              setErros(myerror.errors)               
              }else{
                  let errorMessage = err.response?.data.message || err ;
                  localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
              }
          },
        }
      );


      useEffect(() => {
        changePlan(investment.investment_plan_id);
        window.addEventListener(
          "toggleReinvestmentDrawer",
          (e) => {
           setBarState({ ...barstate, ['bottom']: true });
          },
          false
        );
        
      }, []);

  return (
    <div>
        <Button onClick={()=>togglebottomBar()} size='small' variant="contained" color='primary'>
            Reinvest
        </Button>

      <SwipeableDrawer
      
      anchor={`bottom`}
      open={barstate[`bottom`]}
      onClose={toggleDrawer(`bottom`, false)}
      onOpen={toggleDrawer(`bottom`, true)}> 

      <Card sx={{ borderRadius: '9px', height: '100%'}}>

      <Typography my={3} px={4} sx={{textAlign: 'center'}}  variant="body2" color="secondary">
      Kindly enter any amount from {selectedPlan !== '' &&`
           ${formatPrice(selectedPlan.min_amount )} to  ${formatPrice(selectedPlan.amount_invested )} 
          `        
          }   to invest
      </Typography>

        <CardContent>
        <Box px={1} sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
            <InputLabel htmlFor="amount">Enter Amount</InputLabel>
            <OutlinedInput
                onChange={(e)=>setAmount(e.target.value)}
                defaultValue={parseFloat(investment.amount_invested) + parseFloat(investment.earning)}
                id="amount"
                type={'number'}
                label="Enter Amount"
            />
            </FormControl>

            {(selectedPlan !== '')&&
            <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
            <InputLabel htmlFor="investment_plan">Select Plan</InputLabel>
            {(error !== '' && error.investment_plan) ?
            <Select
            error
            onChange={(e) => {
                setPlanId(e.target.value);
                setTimeout(() => {
                    changePlan(e.target.value);
                }, 1000);
                
            }}
            defaultValue={investment.investment_plan_id}
            labelId="investment_plan"
            id="investment_plan"
            name="investment_plan"
            label={`Select Plan`}
            helperText={error.investment_plan}
            >
            <MenuItem selected value=''>
                Select Plan
            </MenuItem> 
            {plans.map(plan =>(
                <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
            ))}
                       
            
        </Select>
            :
            <Select
            onChange={(e) => {
                setPlanId(e.target.value);
                setTimeout(() => {
                    changePlan(e.target.value);
                }, 1000);
                
            }}
                labelId="investment_plan"
                name="investment_plan"
                id="investment_plan"
                defaultValue={investment.investment_plan_id}
                label={`Select Plan`}
                >
                <MenuItem selected value="">
                    Select Plan
                </MenuItem>  
                {plans.map(plan =>(
                <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
            ))}  

            </Select>
            }
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
            {error !== '' && error.investment_plan}
            </Typography>
            </FormControl>
            }

            {(selectedPlan !== '' && selectedPlan.duration.split(',').length > 0 )&&
            <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
            <InputLabel htmlFor="duration">Select duration</InputLabel>
            {(error !== '' && error.duration) ?
            <Select
            error
            onChange={(e) => setCurrentDuration(e.target.value)}
            labelId="duration"
            id="duration"
            name="duration"
            label={`Select duration`}
            defaultValue={investment.duration}
            helperText={error.duration}
            >
            <MenuItem selected value=''>
                Select duration
            </MenuItem> 
            {selectedPlan.duration.split(',').map(duration =>(
                <MenuItem value={duration}>{duration}</MenuItem>
            ))}             
            
        </Select>
            :
            <Select
                onChange={(e) => setCurrentDuration(e.target.value)}
                labelId="duration"
                name="duration"
                id="duration"
                label={`Select duration`}
                defaultValue={investment.duration}
                >
                <MenuItem selected value="">
                    Select duration
                </MenuItem>              
                {selectedPlan.duration.split(',').map(duration =>(
                <MenuItem value={duration}>{duration}</MenuItem>
                ))}  
            </Select>
            }
            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
            {error !== '' && error.duration}
            </Typography>
            </FormControl>
            }


          <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="source">Select Payment Source</InputLabel>
              {(error !== '' && error.duration) ?
              <Select
              error
              onChange={(e) => setSource(e.target.value)}
              labelId="source"
              id="source"
              name="source"
              label={`Select Payment Source`}

              helperText={error.duration}
              >
              <MenuItem selected value=''>
              Select Gateway
              </MenuItem> 

              <MenuItem selected value="Withdrawable Balance">
              Account Balance (Bal = {formatPrice(mainBal)})
              </MenuItem>

              {/* <MenuItem selected value="Funding Wallet">
              Funding Wallet (Bal = {formatPrice(fundingBal)})
              </MenuItem>

              <MenuItem selected value="Referral Wallet">
              Referral Wallet (Bal = {formatPrice(refBal)})
              </MenuItem>              */}
              
          </Select>
              :
              <Select
                  onChange={(e) => setSource(e.target.value)}
                  labelId="source"
                  name="source"
                  id="source"
                  label={`Select Payment Source`}
                  >
                  <MenuItem selected value=''>
                  Select Gateway
                  </MenuItem> 

                  <MenuItem selected value="Withdrawable Balance">
                  Account Balance (Bal = {formatPrice(mainBal)})
                  </MenuItem>

                  {/* <MenuItem selected value="Funding Wallet">
                  Funding Wallet (Bal = {formatPrice(fundingBal)})
                  </MenuItem>

                  <MenuItem selected value="Referral Wallet">
                  Referral Wallet (Bal = {formatPrice(refBal)})
                  </MenuItem>  */}
              </Select>
              }
              <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
              {error !== '' && error.duration}
              </Typography>
          </FormControl>
        </Box> 

        <Button onClick={postInvestment} fullWidth={true} size="large" color="primary" variant="contained"> 
        {isInvesting ? 'Sending...' : 'Submit Request'}  
        </Button> 
        </CardContent>
           

        </Card>
    </SwipeableDrawer>
    
    </div>
  )
}

export default Reinvestment
