import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { setUser } from './userSlice';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthStatus, setToken } from '../../request/Auth/authSlice';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Currency } from '@depay/local-currency';


import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const Register = () => {
    const [countries, setCountries] = useState('');
    const { isLoading: isloadingCountries, refetch: getCountries } = useQuery(
        "countries",
        async () => {
          return await apiClient.get("/api/countries");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setCountries(res.data.countries);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

    const dispatch = useDispatch();
    const initialLogin = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        address: '',
        referral: '',
        country: ''

    }
   
    const [error, setErros] = useState('');
    
    const [registerForm, setRegisterForm] = useState(initialLogin);
    const appMode = useSelector((state) => state.mode.mode);
    const redirectTo = useSelector((state) => state.redirect.redirect);
    const inviter = useSelector((state) => state.referrals.inviter);

    const navigate = useNavigate();

    const onChange = (e) =>
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
    let currency = Currency.getCode();

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/register`, {
            name: registerForm.name,
            email: registerForm.email,
            password: registerForm.password,
            password_confirmation: registerForm.password_confirmation,
            phone: registerForm.phone,
            address: registerForm.address,
            referral: inviter,
            country: registerForm.country,
            currency: currency
          });
        },

        {
          onSuccess: (res) => {
            localStorage.setItem('auth-token', res.data.access_token);
            const event0 = new Event('updatedStorage');
              window.dispatchEvent(event0);
            dispatch(setUser(res.data.user));
            dispatch(setAuthStatus('authenticated'));
            dispatch(setToken(res.data.access_token));
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);

            if (redirectTo !== '') {
                navigate(redirectTo);
            }else{
                setTimeout(() => {
                    window.location.href  = '/';
                  }, 2000);
                // navigate('/');
              }

          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = async() =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postLogin();
        // await apiClient.get("/sanctum/csrf-cookie").then(() => {
          
        // })
      } 

      useEffect(() => {
        getCountries();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={8} sx={{display: {xs: 'none', md: 'flex'}}}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardMedia
                        component="img"
                        sx={{ width: '100%', height: "100%" }}
                        image='https://res.cloudinary.com/drrsog1ef/image/upload/v1709538899/collage-finance-banner-concept_1_qprnbb.jpg'
                        alt='login'
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <img  src={appMode === 'dark'? 'https://res.cloudinary.com/drrsog1ef/image/upload/v1709464783/binarystack-oneline-white_u82yjn.png' : 'https://res.cloudinary.com/drrsog1ef/image/upload/v1709464783/binarystack-black-oneline_n36n07.png'} style={{width: '154px', height: '50px', marginBottom: '20px'}} alt="logo" />
                    <Divider />
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        Register
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create account
                    </Typography>

                    {(inviter !== '' && inviter !== null) &&
                    <Box mx={2} mt={10}>
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="info">Your referral code is {inviter}</Alert>
                    </Stack>
                    </Box>
                    }

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Full Name">Full Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Full Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Full Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="email">Email</InputLabel>
                            {(error !== '' && error.email) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="email"
                            type={'email'}
                            name="email"
                            label="Email"
                            helperText={error.email}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="email"
                            type={'email'}
                            name="email"
                            label="Email"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.email}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <OutlinedInput
                            onChange={onChange}
                            id="password"
                            name="password"
                            type={'password'}
                            label="Password"
                            />
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="password_confirmation">Confirm Password</InputLabel>
                            {(error !== '' && error.password) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="password_confirmation"
                            name="password_confirmation"
                            type={'password'}
                            label="Confirm Password"
                            helperText={error.password}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="password_confirmation"
                            name="password_confirmation"
                            type={'password'}
                            label="Confirm Password"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.password}
                            </Typography>
                        </FormControl>

                        {(countries !== '' && countries.length > 0 )&&
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="country">Select Country</InputLabel>
                        {(error !== '' && error.country) ?
                        <Select
                        error
                        onChange={onChange}
                        labelId="country"
                        id="country"
                        name="country"
                        label={`Select Country`}
                        helperText={error.country}
                        >
                        <MenuItem selected value=''>
                            Select Country
                        </MenuItem> 
                        {countries.map(country =>(
                            <MenuItem value={country.name}>{country.name} </MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                            onChange={onChange}
                            labelId="country"
                            name="country"
                            id="country"
                            label={`Select Country`}
                            >
                            <MenuItem selected value="">
                                Select Country
                            </MenuItem>              
                            {countries.map(country =>(
                            <MenuItem value={country.name}>{country.name} </MenuItem>
                            ))} 
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.country}
                        </Typography>
                    </FormControl>
                        }
                        


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="phone">Phone</InputLabel>
                            {(error !== '' && error.phone) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="phone"
                            name="phone"
                            type={'tel'}
                            label="Phone"
                            helperText={error.phone}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="phone"
                            name="phone"
                            type={'tel'}
                            label="Phone"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.phone}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="referral">Referral Code (Optional)</InputLabel>

                            {(error !== '' && error.referral) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            defaultValue={inviter}
                            id="referral"
                            name="referral"
                            type={'text'}
                            label="Referral Code (Optional)"
                            helperText={error.referral}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            defaultValue={inviter}
                            id="referral"
                            name="referral"
                            type={'text'}
                            label="Referral Code (Optional)"
                            />
                            }

                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                                {error !== '' && error.referral}
                            </Typography>

                        </FormControl>

                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Address</InputLabel>
                            {(error !== '' && error.address) ?
                            <OutlinedInput
                            error
                            helperText={error.address}
                            onChange={onChange}
                            minRows={2} multiline
                            id="address"
                            name="address"
                            type={'text'}
                            label="Address"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="address"
                            name="address"
                            type={'text'}
                            label="Address"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.address}
                            </Typography>
                        </FormControl>
                        <FormControlLabel
                            control={
                            <Checkbox  name="antoine" />
                            }
                            label="I accept terms and conditions"
                        />

                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Link  to='/login' component={RouterLink} underline="none" color="inherit">
                            Login to account
                            </Link>
                        </Box>
                    </CardContent>                    
                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Registering...' : 'Create Account'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default Register
